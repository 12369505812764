<template>
  <div class="wx-temp"></div>
</template>
<script>
import { getUserInfoByToken } from '@/api/api';
export default {
  name: 'WxTemp',
  emits: ['cart'],
  created() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      // let aa = 'https://testingmall.gree.com/#/wxTemp?callBackUrl=ef6cd76f-2cd9-4db9-8674-2734d30e9b95&token=%7C%7C1007176554%7C204d35bb-c747-4086-a542-1286b3e6a0b7&e=1677133760301'
      let localUrl = decodeURIComponent(window.location.href);
      let paramsInfo = {};
      if (localUrl.indexOf('?') != -1) {
        let str =
          localUrl.replace(/^([^\?]*).*$/, ($all, $1) => {
            return $1;
          }) + '?';
        let strs = localUrl.replace(str, '').split('&');
        // 切除callBack=字段
        for (let i = 0; i < strs.length; i++) {
          paramsInfo[
            strs[i].replace(/^([^\=]*).*$/, ($all, $1) => {
              return $1;
            })
          ] = strs[i].replace(
            strs[i].replace(/^([^\=]*).*$/, ($all, $1) => {
              return $1;
            }) + '=',
            '',
          );
        }
      }
      // 微信测试登录
      let backToken = paramsInfo.token ? paramsInfo.token : '';
      // 密码输入错误次数大于5次 || 用户被锁定
      //   if (paramsInfo?.result == '2235' || paramsInfo?.result == '35032') {
      //     storage.session.set('isOverRun', true)
      //     if (paramsInfo?.message) { Toast(paramsInfo?.message) }
      //     this.$router.go(-1)
      //   } else {
      //   }
      const { data, result } = await getUserInfoByToken({ token: backToken });
      if (result === 0) {
        this.$store.commit('SET_USER_INFO', data);
        this.$emit('cart');
        this.$router.replace({ path: '/home' });
        // setTimeout(() => {
        //   window.location.reload();
        // },1000)
      } else {
        this.$Message.error({
          content: '微信登录失败',
          duration: 2,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.wx-temp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
